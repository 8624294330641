import React, { forwardRef } from 'react';
import { makeStyles, Button as MUIButton} from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
  button: {
    position: 'relative',
    zIndex: 1,
    background: 'linear-gradient(252.03deg, #FF0000 0%, #FF5C00 100%)',
    color: 'white',
    textTransform: 'unset',
    width: 280,
    height: 48,
    fontSize: 15,
    fontWeight: 600,
    borderRadius: 12,
    whiteSpace: 'nowrap',

    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
      width: 240,
    },

    '& svg': {
      marginRight: 5,
    },

    '&[disabled]': {
      color: 'rgba(255, 255, 255, 0.5)',
      background: 'linear-gradient(252.03deg, #ff8080 0%, #ffae80 100%)',
    },
  },

  round: {
    borderRadius: 99,
  },

  small: {
    width: 'auto',
    height: 'auto',
    fontSize: 15,
    padding: theme.spacing(0.5, 1.5),
  },

  secondary: {
    color: '#FF2D2D',
    border: '1px solid #e5e5e5',
    background: '#fff',

    '&:hover': {
      background: '#F5F5F5',
      border: '1px solid rgba(255, 0, 0, 1)'
    },
  },
}));

const Button = forwardRef(({ secondary, small, round, ...props }, ref) => {
  const classes = useStyles();

  return (
    <MUIButton
      ref={ref}
      disableElevation
      variant="contained"
      size="large"
      type="submit"
      fontSize="small"
      {...props}
      className={classnames(classes.button, {
        [classes.small]: small,
        [classes.secondary]: secondary,
        [classes.round]: round,
      })}
    />
  );
});

export default Button;
