import React from 'react';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 10,
    right: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
  },
}));

const NavMenu = ({ children, className, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.root, className)} {...props}>
      {children}
    </div>
  );
};

export default NavMenu;
