import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import { navigate } from 'gatsby';

import SEO from '../../components/seo';
import Button from '../../components/Button';
import NavMenu from '../../components/NavMenu';
import LogoTwitter from '../../assets/images/svg/twitter.svg';

import authService from '../../services/authService';

const useStyles = makeStyles(theme => ({
  loginForm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'calc(100vh - 100px)',

    '& form': {
      display: 'block',
      width: '100%',
      maxWidth: 564,
      textAlign: 'center',
    },

    '& label': {
      display: 'block',
      margin: theme.spacing(3, 0),
      textAlign: 'initial',
    },

    '& label span': {
      display: 'block',
      margin: theme.spacing(0, 0, 1),
      fontSize: 15,
      fontWeight: 'bold',
    },

    '& label input[type=text], & label input[type=password]': {
      width: '100%',
      maxWidth: 564,
      padding: 14,
      fontSize: 15,
      border: '1px solid #BFBFBF',
      backgroudn: '#fff',
      borderRadius: 5,
    },

    '& button[type=submit]': {
      display: 'block',
      margin: theme.spacing(3, 'auto', 0),
      width: '100%',
      maxWidth: 170,
    },
  },

  hasError: {
    '& label input[type=text], & label input[type=password]': {
      borderColor: '#FF2D2D',
    },
  },

  title: {
    fontSize: 32,
    margin: theme.spacing(0),
  },

  errorMessage: {
    fontSize: 15,
    margin: theme.spacing(1, 0, 3),
    color: '#FF2D2D',
  },
  
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
    padding: theme.spacing(3, 3, 6),
    textAlign: 'center',
    borderTop: `1px solid ${theme.palette.grey['200']}`,
    color: theme.palette.grey['400'],
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const [loginError, setLoginError] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoginError(false);
    setLoggingIn(true);

    const { username, password } = e.nativeEvent.target.elements;

    if (!username || !password) return;

    const response = await authService.login(username.value, password.value);

    setLoggingIn(false);

    if (!response) {
      setLoginError(2);
    } else
    if (response.status === 401 || response.data.error) {
      setLoginError(1);
    } else
    if (response.status === 200) {
      if (response.data && response.data.stage === 'COMPLETED') {
        authService.setUser({
          userId: response.data.userId,
          token: response.data.token,
        });

        navigate('/app/room');
      }
    }
  };

  return (
    <>
      <SEO title="Login" />

      <NavMenu>
        <a href="//twitter.com/lockerroomapp" target="_blank" rel="noreferrer noopener">
          <LogoTwitter />
        </a>
      </NavMenu>

      <div className={classnames(classes.loginForm, {
        [classes.hasError]: loginError,
      })}>
        <form onSubmit={onSubmit}>
          <h1 className={classes.title}>Sign in to Locker Room</h1>
          
          {
            loginError && (
              <p className={classes.errorMessage}>
                {{
                  1: 'You entered an incorrect username, password, or both.',
                  2: 'There was a problem logging you in, please try again later.',
                }[loginError]}
              </p>
            )
          }

          <label htmlFor="username">
            <span>Username</span>
            <input type="text" id="username" name="username" disabled={loggingIn} />
          </label>

          <label htmlFor="password">
            <span>Password</span>
            <input type="password" id="password" name="password" disabled={loggingIn} />
          </label>

          <Button type="submit" disabled={loggingIn}>
            Login
          </Button>
        </form>
      </div>

      <footer className={classes.footer}>
        Copyright 2020 Betty Labs
      </footer>
    </>
  );
};

export default LoginPage;
